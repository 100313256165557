

export const video = () => {

  const videoStart = document.querySelectorAll('.js-video-start');

  if ( videoStart ) {
    for (let btn of videoStart){
      btn.addEventListener('click',function(){
        
        let src = btn.dataset.video;

        btn.parentElement.innerHTML = `<iframe width="100%" height="100%" src="${src}?si=I10YEh005DoWuYhm&amp;controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`

      });
    }
  }

}






