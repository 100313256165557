

export const dropdown = () => {

  const dropdownBtns = document.querySelectorAll('.js-dropdown-btn');

  if ( dropdownBtns ) {
    for (let btn of dropdownBtns){
      btn.addEventListener('click',function(){
        btn.classList.toggle("dropdown__btn_open");
        btn.parentElement.querySelector(".dropdown__img").classList.toggle("dropdown__img_open");
        if ( btn.parentElement.querySelector(".dropdown__img").classList.contains("dropdown__img_open") ) {
          btn.parentElement.querySelector(".dropdown__slide").style.height = btn.parentElement.querySelector(".dropdown__hidden").offsetHeight + "px";
        } else {
          btn.parentElement.querySelector(".dropdown__slide").style.height = 0 + "px";
        }
      });
    }
  }

}