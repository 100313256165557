console.log('components');

import { scrollEvents } from "./components/scrollEvents";
scrollEvents();

import { swipers } from "./components/swipers";
swipers();

import { inputs } from "./components/inputs";
inputs();

import { header } from "./components/header";
header();

import { scrollTo } from "./components/scrollTo";
scrollTo();

import { dropdown } from "./components/dropdown";
dropdown();

import { video } from "./components/video";
video();

import { modal } from "./components/modal";
modal();

import { gallery } from "./components/gallery";
gallery();

import { filter } from "./components/filter";
filter();