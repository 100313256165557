import { modal } from "./modal";

export const scrollEvents = () => {

  const mainTitle = document.querySelectorAll(".js-main-title-appear");
  const titles = document.querySelectorAll(".js-title-appear");

  const stepsProgress = document.querySelector('.steps__active');
  const stepsNumbers = document.querySelectorAll('.steps__number');

  const stepsSection = document.querySelector('.steps');
  const stepsInner = document.querySelector('.steps__inner');
  const blogSection = document.querySelector('.content_main-blog');
  const blogInner = document.querySelector('.content__inner_main-blog');


  /* if ( stepsSection ) {
    
    let a = stepsSection.clientHeight;
    let b = window.getComputedStyle(stepsInner).marginTop.split("px")[0];
    let c = window.getComputedStyle(stepsSection).top.split("px")[0];

    let blogTop = +a + +b + +c + 20;

    blogSection.style.top = blogTop + "px";


    stepsInner.style.height = 20 + +stepsInner.clientHeight + +blogInner.clientHeight + +window.getComputedStyle(stepsInner).marginTop.split("px")[0] + +window.getComputedStyle(stepsInner).marginBottom.split("px")[0] + "px";

    blogSection.style.marginBottom = 80 + "px";

  } */


  setTimeout(() => {
    if ( mainTitle ) {
      for (let title of mainTitle) {
        title.classList.add("title_show");
      }
    }
  }, 500);

  
  document.addEventListener("scroll", function(){
    //console.clear();
    for (let title of titles) {
      
      //console.log( title.innerHTML + ": " + title.offsetTop );
      //console.log( window.pageYOffset + window.innerHeight );

      if ( (window.pageYOffset + window.innerHeight) > (title.offsetTop + title.offsetHeight + 100) ) {
        title.classList.add("title_show");
      }
    }    
  });


  var stepsDistance = +document.querySelector('.sticky')?.offsetTop + 100;

  if ( stepsProgress ){
    document.querySelector('body').onscroll = function() {
    
      //console.log(window.pageYOffset);
  
      if ( window.pageYOffset >= (+stepsDistance) ) {
        //console.log("step1");
        document.querySelector('.steps__number_active').classList.remove("steps__number_active");
        stepsNumbers[1].classList.add("steps__number_active");
  
        stepsProgress.classList.add("steps__active_step-1");
        document.querySelector('.steps__row').classList.add("steps__row_step-1");
      } else {
        stepsProgress.classList.remove("steps__active_step-1");
        document.querySelector('.steps__row').classList.remove("steps__row_step-1");
        
        document.querySelector('.steps__number_active').classList.remove("steps__number_active");
        stepsNumbers[0].classList.add("steps__number_active");
      }
  
      if ( window.pageYOffset >= (+stepsDistance + 1000) ) {
        //console.log("step2");
        document.querySelector('.steps__number_active').classList.remove("steps__number_active");
        stepsNumbers[2].classList.add("steps__number_active");
  
        stepsProgress.classList.add("steps__active_step-2");
        document.querySelector('.steps__row').classList.add("steps__row_step-2");
      } else {
        stepsProgress.classList.remove("steps__active_step-2");
        document.querySelector('.steps__row').classList.remove("steps__row_step-2");
      }
  
      if ( window.pageYOffset >= (+stepsDistance + 2000) ) {
        //console.log("step3");
        document.querySelector('.steps__number_active').classList.remove("steps__number_active");
        stepsNumbers[3].classList.add("steps__number_active");
  
        stepsProgress.classList.add("steps__active_step-3");
        document.querySelector('.steps__row').classList.add("steps__row_step-3");
      } else {
        stepsProgress.classList.remove("steps__active_step-3");
        document.querySelector('.steps__row').classList.remove("steps__row_step-3");
      }
      
      if ( window.pageYOffset >= (+stepsDistance + 3000) ) {
        //console.log("step4");
        document.querySelector('.steps__number_active').classList.remove("steps__number_active");
        stepsNumbers[4].classList.add("steps__number_active");
  
        stepsProgress.classList.add("steps__active_step-4");
        document.querySelector('.steps__row').classList.add("steps__row_step-4");
      } else {
        stepsProgress.classList.remove("steps__active_step-4");
        document.querySelector('.steps__row').classList.remove("steps__row_step-4");
      }




      /* if ( window.pageYOffset >= (+stepsDistance + 4420) ) {
        document.querySelector('.content__inner_main-blog').classList.remove("content__inner_sticky");
      } else {
        document.querySelector('.content__inner_main-blog').classList.add("content__inner_sticky");
      } */


      /* if ( window.pageYOffset  >= 1959 && window.pageYOffset < (+stepsDistance + 4420) ) {
        document.querySelector('.content__inner_main-blog').classList.add("content__inner_sticky");
      } else {
        document.querySelector('.content__inner_main-blog').classList.remove("content__inner_sticky");
      } */
  
    };
  }
  


}