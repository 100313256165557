import Inputmask from 'inputmask';

export const inputs = () => {

  const inputs = document.querySelectorAll('.js-form-input');



  document.querySelector("#modalForm")?.addEventListener("submit",function(e){

    e.preventDefault();
    
    document.querySelector(".modal__body_main").classList.add("modal__body_hide");
    document.querySelector(".modal__body_success").classList.remove("modal__body_hide");

  });



  if ( inputs ) {
    
    for (let input of inputs) {

      input.setCustomValidity(" ");
      if ( input.getAttribute('name') == 'comment' ) {
        input.setCustomValidity("");
      }

      input.oninput = function() {
        if ( input.getAttribute('name') == 'name' ) {
          this.value = this.value.replace(/[^a-zA-Zа-яА-Я\s]/g,'');
        }
        
        if ( input.getAttribute('name') == 'email' ) {
          this.value = this.value.replace(/[^0-9@.a-zA-Z]/g,'');
        }
      }
  
      input.onfocus = function() {
        input.previousElementSibling.classList.add("form__placeholder_active");
        input.parentElement.classList.add("form__input_active");
      }

      input.onblur = function() {
  
        if ( input.value == '' ) {
          input.nextElementSibling.classList.remove("form__check_active");
          input.previousElementSibling.classList.remove("form__placeholder_active");
          input.parentElement.classList.remove("form__input_active");
        } else {
          input.nextElementSibling.classList.add("form__check_active");
        }      

        if ( input.getAttribute('name') == 'name' ){
          if ( input.value != '' ) {
            valid(input);
          } else {
            invalid(input);
          }
        }

        if ( input.getAttribute('name') == 'phone' ){
          if ( input.value.indexOf("_") == -1 && input.value.split("")[4] != 0 && input.value != '' ) {
            valid(input);
          } else {
            invalid(input);
          }
        }

        if ( input.getAttribute('name') == 'email' ){
          if ( input.value.indexOf("@") > -1 && input.value.indexOf(".") > -1 && input.value != '' ) {
            valid(input);
          } else {
            invalid(input);
          }
        }

        function valid(el) {
          el.previousElementSibling.classList.remove("form__placeholder_error");
          el.setCustomValidity("");
        }

        function invalid(el) {
          el.previousElementSibling.classList.add("form__placeholder_error");
          el.nextElementSibling.classList.remove("form__check_active");
          el.setCustomValidity(" ");
        }
  
      }
  
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
      if(document.querySelectorAll('input[type="tel"]').length > 0) {
        let selector = document.querySelectorAll('input[type="tel"]');
        let im = new Inputmask('+7 (999) 999-99-99', {
          showMaskOnHover: false,
        });
        im.mask(selector);
      }
  })
  

}