

export const filter = () => {


  const filterBtns = document.querySelectorAll('.js-filter');


  if ( filterBtns ) {

    for (let filter of filterBtns) {
      filter.addEventListener('change',function(){
        console.log(
          this.value
        );
      });
    }

  }


}