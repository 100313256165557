import Swiper from 'swiper/bundle';

export const swipers = () => {
 

  var swiperCases = new Swiper(".js-swiper-cases", {
    slidesPerView: 3,
    spaceBetween: 20,
    freeMode: true,
    speed: 7000,
    autoplay: {
      delay: 0.1,
    },
    loop: true,
    resistance: false,
    slidesPerView:'auto',
    disableOnInteraction: false,
    on: {
      slideChangeTransitionEnd: function() {
        setTimeout(() => {
          swiperCases.autoplay.start();
        }, 2000);
      }
    },
  });

  var swiperHelp = new Swiper(".js-swiper-help", {
    slidesPerView: 4,
    spaceBetween: 5,
    loop: true,
    centeredSlides: true,
    slidesPerView:'auto',
    disableOnInteraction: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      576: {
        spaceBetween: 20
      },
    }
  });

  var swiperDropdown = new Swiper(".js-swiper-content", {
    slidesPerView: 4,
    spaceBetween: 20,
    loop: true,
    centeredSlides: true,
    slidesPerView:'auto',
    disableOnInteraction: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      formatFractionCurrent: function (number) {
        //console.log( ('' + number).slice(-2) );
          return ('' + number).slice(-2);
      },
      formatFractionTotal: function (number) {
        //console.log( ('' + number).slice(-2) );
          return ('' + number).slice(-2);
      },
      renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
                ' / ' +
                '<span class="' + totalClass + '"></span>';
      }
    },
  });

  var swiperTrust = new Swiper(".js-swiper-trust", {
    slidesPerView: 3,
    spaceBetween: 20,
    freeMode: true,
    speed: 7000,
    autoplay: {
      delay: 0.1,
    },
    loop: true,
    resistance: false,
    slidesPerView:'auto',
    disableOnInteraction: false,
    on: {
      slideChangeTransitionEnd: function() {
        setTimeout(() => {
          swiperTrust.autoplay.start();
        }, 2000);
      }
    }
  });
  

  var swiperComission = new Swiper(".js-swiper-comission", {
    slidesPerView: "auto",
    spaceBetween: 10,
    centeredSlides: false,
    disableOnInteraction: false,
  });
  

}