

export const modal = () => {

  
  const modalOpen = document.querySelectorAll('.js-modal-open');
  const modal = document.querySelectorAll(".modal");
  const modalClose = document.querySelectorAll(".js-modal-close");
  var distance = 0;

  const filesInput = document.querySelector('.js-modal-files');
  const filesList = document.querySelector('.modal__files-list');


  document.addEventListener("click", function(e){

    if(e.target && e.target.className.indexOf("js-modal-open") > -1) {

      distance = window.pageYOffset;

      for(var z = 0; z < modal.length; z++){
        if ( modal[z].dataset.modal == e.target.dataset.modal ) {
          modal[z].classList.add("modal_open");
          document.querySelector(".site-container").classList.add("dis-scroll");
         
          document.querySelector(".site-container").scrollTo({
            top: distance,
            left: 0,
          });
        }
      }

    }
  
  });

  if ( modalClose ){
    for(var i = 0 ; i < modalClose.length; i++){

      modalClose[i].addEventListener("click", function(){
        
        document.querySelector(".modal_open").classList.remove("modal_open");
        document.querySelector(".site-container").classList.remove("dis-scroll");
   
        window.scrollTo({
          top: distance,
          left: 0,
        });

      });
    }
  }

  if ( modal ) {
    for (var i = 0; i < modal.length; i++) {
      modal[i].addEventListener("click",function(event){
        if (this == event.target){
          document.querySelector(".modal_open").classList.remove("modal_open");
          document.querySelector(".site-container").classList.remove("dis-scroll");
        }
        window.scrollTo({
          top: distance,
          left: 0,
        });
      });
    }

  
    document.addEventListener('keydown', function(e) {
      let keyCode = e.keyCode;
      if (keyCode === 27) {
        document.querySelector(".modal_open")?.classList.remove("modal_open");
        document.querySelector(".site-container").classList.remove("dis-scroll");
        window.scrollTo({
          top: distance,
          left: 0,
        });
      }
      
    });
  }


  if ( filesInput ) {
    var dt;
    dt = new DataTransfer();
    //dt.items.add(new File([], ''));
    filesInput.files = dt.files;
  
    filesInput.onchange = () => {
      dt = new DataTransfer();
      
      filesList.classList.add("modal__files-list_margin");
      filesList.innerHTML = "";
  
      filesStringsCreate();
    }
  
   
    document.addEventListener("click", function(e){
  
      if(e.target && e.target.className.indexOf("modal__files-del") > -1) {
  
        let num = e.target.dataset.number;
  
        dt = new DataTransfer()
        for (let file of filesInput.files){
          if (file !== filesInput.files[num]){ 
            dt.items.add(file)
          }
        }
  
        //filesInput.onchange = null;
        filesInput.files = dt.files;
  
        if ( filesInput.files.length == 0 ) {
          filesList.classList.remove("modal__files-list_margin");
        }
  
        /* 
        console.log("—————————");
        for (var z = 0; z < filesInput.files.length; ++z) {
          let name = filesInput.files.item(z).name;
          console.log("file name: " + name);
        } */
  
        filesStringsCreate();
  
      }
    
    });
  
    function filesStringsCreate() {
      filesList.innerHTML = "";
  
      for (let i = 0; i < filesInput.files.length; ++i) {
        let name = filesInput.files.item(i).name;
        //console.log("file name: " + name);
        filesList.innerHTML += `
          <div class="modal__files-line">
            <img src="/img/svg/file.svg" alt="">
            <div class="modal__files-name">${name}</div>
            <div class="modal__files-del" data-number="${i}">✖</div>
          </div>
        `;
      }
    }
  }

  

}






